import Axios, { AxiosInstance, AxiosRequestConfig, AxiosError } from 'axios'

export interface CieApiAxiosMutatorConfig {
  baseUrl: string
  apiKey: string
}

let _config: CieApiAxiosMutatorConfig | undefined
let _instance: AxiosInstance | undefined

export const setAxiosConfig = (config: CieApiAxiosMutatorConfig) =>
  (_config = config)

export const getAxiosConfig = () => _config

const getInstance = () => {
  if (!_config)
    throw `Cannot get instance without module scoped config. Set it with setAxiosConfig().`

  if (!_instance) {
    _instance = Axios.create({
      baseURL: _config.baseUrl,
      timeout: 10000,
      headers: {
        Accept: 'application/camel+json',
        Authorization: `ApiKey ${_config.apiKey}`,
      },
    })
  }

  return _instance
}

export const cieInstance = <T>(config: AxiosRequestConfig): Promise<T> => {
  const instance = getInstance()
  if (!instance) throw `Invalid Axios instance.`

  const source = Axios.CancelToken.source()
  const promise = instance({ ...config, cancelToken: source.token }).then(
    ({ data }) => data
  )

  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled')
  }

  return promise
}

export type ErrorType<Error> = AxiosError<Error>
export default cieInstance

// react-query error type
